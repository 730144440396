
export interface ProjectExperience {
  title: string;
  intro?: string;
  description: string[];
  skills: [string, string][];
  image?: string;
  isGroup: boolean;
  stars: 1 | 2 | 3;
  actions: SingleAction[];
}

export interface SingleAction {
  label: string;
  url: string;
  isDisabled?: boolean;
}

export const PROJECTS: ProjectExperience[] = [
  {
    title: 'NTU Mods',
    intro: 'NTU Mods is NTU\'s student-made module management system to help students plan their modules and timetable, created by some members of NTU Students\' Union Information Technology Committee.',
    description: [
      "Spearheaded the development as the tech lead of NTUSU, first proposing the project to the executive committee in September 2023 and starting development in October 2023.",
      "Collaborated with the project manager and UI/UX designers to define the initial user story, draft the product requirements document (PRD), and provide feedback on UI/UX designs.",
      "Managed backend data processing and implemented web scraping services to periodically retrieve and update data from the NTU website, and created REST APIs for frontend consumption.",
      "Contributed to some frontend feature development, compiled user feedback from feedback forms, managed bug reports, and oversaw backlog prioritization.",
      "Used by over 3,000 students across 15+ countries in August 2024 (data via Google Analytics).",
    ],
    skills: [
      ['Python', 'purple'],
      ['TypeScript', 'purple'],

      ['React', 'blue'],
      ['Next.js', 'blue'],
      ['Zustand', 'blue'],
    
      ['Django', 'green'],
      ['PostgreSQL', 'green'],
      ['Web Scraping', 'green'],

      ['REST API', 'cyan'],

      ['AWS', 'red'],
      ['Docker', 'red'],

      ['GitHub', 'orange'],
      ['CI/CD', 'orange'],

      ['Leadership', 'pink'],
      ['Project Management', 'pink'],
      ['UI/UX Design', 'pink'],
      ['Code Review', 'pink'],
    ],
    image: '/images/ntumods.png',
    isGroup: true,
    stars: 3,
    actions: [
      {
        'label': 'View Live Project',
        'url': 'https://ntumods.org'
      },
    ],
  },
  {
    title: 'NGS Living Pictures',
    intro: 'A volunteering project in a collaboration between NTU and National Gallery Singapore, where I contributed as Frontend Developer to create Living Pictures - a digital publication website.',
    description: [
      "Developed the Living Pictures website from scratch using React, TypeScript, and styled components (CSS), creating custom components from scratch without UI libraries with 100% custom-made components.",
      "Implemented responsive design, accessibility, animations, and various frontend features, focused on attention to detail and delivering high-quality user interface and experience.",
      "Implemented a CI/CD pipeline with GitHub Actions to automate React testing, building, and deploying Docker images to DigitalOcean (IaaS) server, streamlining live deployment"
    ],
    skills: [
      ['TypeScript', 'purple'],

      ['React', 'blue'],
      ['CSS', 'blue'],
      ['Styled Components', 'blue'],
      ['Responsive Design', 'blue'],

      ['IaaS', 'red'],
      ['Docker', 'red'],

      ['GitHub', 'orange'],
      ['CI/CD', 'orange'],

      ['UI/UX Design', 'pink'],
    ],
    image: '/images/ngs.png',
    isGroup: false,
    stars: 3,
    actions: [
      {
        'label': 'View Live Project',
        'url': 'https://ngs.michac789.com'
      },
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/ngs-living-pictures',
      }
    ],
  },
  {
    title: 'Othello Desktop Game',
    intro: 'Fully functional Othello game complete with user interface, game logic, and AI player of 6 difficulty levels. Available to download as executable file on Windows / MAC, you can download the game and try it out yourself!',
    description: [
      "Developed a fully functional Othello game with an intuitive GUI, robust game logic, and an AI opponent with 6 difficulty levels.",
      "Built the game using Python and Pygame for the GUI, implementing the Minimax algorithm with Alpha-Beta pruning to optimize AI decision-making, trained through supervised learning.",
      "Designed a user-friendly interface with features like game restart, undo move, fully customized game board and pieces, and human vs AI or human vs human or AI vs AI modes.",
      "Released the game as an executable file for Windows and MAC, available for download.",
    ],
    skills: [
      ['Python', 'purple'],
      ['Pygame', 'yellow'],
      ['Game Development', 'yellow'],
      ['AI Agent', 'teal'],
      ['Machine Learning', 'teal'],
    ],
    image: '/images/othello.png',
    isGroup: false,
    stars: 2,
    'actions': [
      {
        'label': 'Download Game',
        'url': 'https://github.com/michac789/Othello/releases/tag/v1.0.0',
      },
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/Othello',
      }
    ],
  },
  // TODO - C# .NET here
  {
    title: 'FixedSense AI',
    intro: 'FIXEDSENSE AI is an AI-powered virtual assistant that helps the visually impaired perceive their surroundings through sound. This project reached the semi-finals of the MLDA Deep Learning Week Hackathon 2022.',
    description: [
      "Developed an AI assistant using SSD MobileNet COCO API to detect 80 object categories, with priority-based detection and distance scaling.",
      "Implemented speech recognition for hands-free mode selection and multi-threading for real-time auditory feedback.",
      "Designed three interaction modes: Aware (continuous detection), Warn (proximity alerts), and Search (focus on a specific object).",
    ],    
    skills: [
      ['Python', 'purple'],
      ['Deep Learning', 'teal'],
      ['Computer Vision', 'teal'],
      ['Speech Recognition', 'teal'],
    ],
    image: '/images/fixedsense.png',
    isGroup: true,
    stars: 2,
    'actions': [
      {
        'label': 'View Submission',
        'url': 'https://devpost.com/software/fix6dsense-ai',
      },
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/MLDA-DLW-Hackathon-2022',
      },
    ],
  },
  {
    title: 'Buying Frenzy Backend',
    intro: 'A REST API server for a restaurant, built with NestJS and PrismaORM, featuring authentication, extensive testing, and deployed on AWS Elastic Beanstalk.',
    description: [
      "Developed a fully functional backend with NestJS and PrismaORM, integrating PostgreSQL and Docker for development.",
      "Implemented JWT authentication, robust error handling, and 127 test cases using Jest covering all endpoints and edge cases including database mocking.",
      "Deployed on AWS Elastic Beanstalk with an RDS-hosted PostgreSQL database, ensuring scalability and reliability.",
    ],
    skills: [
      ['TypeScript', 'purple'],
      ['Node.js', 'green'],
      ['NestJS', 'green'],
      ['PrismaORM', 'green'],
      ['PostgreSQL', 'green'],
      ['REST API', 'cyan'],
      ['AWS', 'red'],
      ['Docker', 'red'],
      ['Jest', 'orange'],
      ['JWT Authentication', 'orange'],
    ],
    image: '/images/buyingfrenzy.jpg',
    isGroup: false,
    stars: 2,
    actions: [
      {
        label: 'View Source Code',
        url: 'https://github.com/michac789/Buying-Frenzy-Backend',
      }
    ],
  },
  {
    title: 'Task Master',
    intro: 'A full-stack task management app with authentication, REST API, and a fully responsive UI, built with Vanilla JavaScript and Flask.',
    description: [
      "Developed a TODO list app with a Flask backend using SQLAlchemy, JWT authentication, and robust validation.",
      "Implemented a frontend with pure HTML, CSS, and JavaScript, ensuring a smooth user experience without the use of frameworks or libraries.",
      "Deployed on Digital Ocean with automated CI/CD, Gunicorn for production web server, Caddy as a reverse proxy, and Docker for containerization.",
    ],
    skills: [
      ['Python', 'purple'],
      ['JavaScript', 'purple'],
      ['HTML', 'blue'],
      ['CSS', 'blue'],
      ['Bootstrap', 'blue'],
      ['Flask', 'green'],
      ['SQL', 'green'],
      ['Docker', 'red'],
      ['Digital Ocean', 'red'],
      ['CI/CD', 'orange'],
      ['JWT Authentication', 'orange'],
      ['Unit Tests', 'orange'],
    ],
    image: '/images/taskmaster.png',
    isGroup: false,
    stars: 2,
    actions: [
      {
        label: 'View Live Project',
        url: 'https://taskmaster.michac789.com'
      },
      {
        label: 'View Source Code',
        url: 'https://github.com/michac789/taskmaster',
      }
    ],
  },
  {
    title: 'MOBLIMA',
    intro: 'A Java-based movie ticketing system for online booking, movie management, and sales reporting.',
    description: [
      "Developed a full-featured movie ticketing system with Java, following OOP principles.",
      "Implemented user authentication, seat booking, and admin management for movies, cinemas, and pricing; ensured an error-free application, passing all test cases.",
      "Documented with Javadoc and UML diagrams, ensuring maintainability and clarity.",
    ],
    skills: [
      ['Java', 'purple'],
      ['Javadoc', 'blue'],
      ['GitHub', 'orange'],
      ['Unit Tests', 'orange'],
      ['Object-Oriented Programming', 'pink'],
      ['UML', 'pink'],
    ],
    image: '/images/moblima.jpg',
    isGroup: true,
    stars: 2,
    actions: [
      {
        label: 'View Source Code',
        url: 'https://github.com/michac789/SC2002-MOBLIMA',
      },
    ],
  },
  {
    title: 'Flappy Bird Game',
    intro: 'A web-based Flappy Bird clone built with C# and Unity, integrating WebGL through Flask server.',
    description: [
      "Developed a simple Flappy Bird game using C# and Unity, replicating the classic gameplay experience.",
      "Integrated WebGL for web play, ensuring cross-platform accessibility.",
      "Implemented a high score feature, storing and displaying top scores.",
      "Enabled JavaScript-to-C# API integration for seamless web app interaction.",
    ],
    skills: [
      ['C#', 'purple'],
      ['Python', 'purple'],
      ['JavaScript', 'purple'],
      ['Unity', 'yellow'],
      ['Game Development', 'yellow'],
      ['HTML', 'blue'],
      ['CSS', 'blue'],
      ['Bootstrap', 'blue'],
      ['Flask', 'green'],
      ['SQL', 'green'],
      ['Docker', 'red'],
      ['Digital Ocean', 'red'],
    ],
    image: '/images/flappybird.png',
    isGroup: false,
    stars: 1,
    actions: [
      {
        label: 'View Live Project',
        url: 'https://flappy-bird.michac789.com',
      },
      {
        label: 'View Source Code',
        url: 'https://michac789.com/flappy-bird',
      },
    ],
  },
  {
    title: 'AutoML',
    intro: 'AutoML is a web app that lets non-technical users upload Excel data, automatically compare and fine-tune classifier models, and generate predictions—no coding or ML expertise needed. Ideal for business users seeking data-driven insights with minimal technical effort.',
    description: [
      "Innovated and developed an intuitive web application enabling users to train high-accuracy classifier models effortlessly by uploading data and clicking a few buttons.",
      "Designed the frontend using React, TypeScript, and Chakra UI, implementing features such as pagination, alerts, and tables for an enhanced user experience.",
    ],
    skills: [
      ['Python', 'purple'],
      ['TypeScript', 'purple'],
      ['React', 'blue'],
      ['Chakra UI', 'blue'],
      ['Flask', 'green'],
      ['Machine Learning', 'teal'],
      ['Docker', 'red'],
    ],
    image: '/images/automl.jpg',
    isGroup: false,
    stars: 2,
    actions: [
      {
        label: 'View Demo',
        url: 'https://drive.google.com/drive/folders/1EcykN2br8xg_-GUYaZfnwcGQyhsptrPy'
      },
    ],
  },
  {
    title: 'NTUSU Portal',
    intro: 'NTU Student Union Portal is a website offering various services for undergraduate students in NTU, created by NTU Student Union Information Technology Committee.',
    description: [
      "Led a team of 12 people to create the NTUSU Portal, a website offering services for NTU undergraduate students.",
      "Developed the website with a Django backend and a React frontend, integrating features like event registration, feedback forms, and announcements.",
      "Managed the backend data processing and APIs, ensuring data integrity and security.",
      "Deployed on AWS with CI/CD pipelines, ensuring reliability and scalability.",
    ],
    skills: [
      ['Python', 'purple'],
      ['TypeScript', 'purple'],
      ['React', 'blue'],
      ['TailwindCSS', 'blue'],
      ['Redux', 'blue'],
      ['Django', 'green'],
      ['AWS', 'red'],
      ['Docker', 'red'],
      ['GitHub', 'orange'],
      ['CI/CD', 'orange'],
      ['Leadership', 'pink'],
      ['Project Management', 'pink'],
      ['Code Review', 'pink'],
    ],
    image: '/images/ntusuportal.png',
    isGroup: true,
    stars: 3,
    'actions': [
      {
        'label': 'View Live Project',
        'url': 'https://app.ntusu.org'
      },
    ],
  },
  {
    "title": "CS50 AI",
    "intro": "This repository is based on CS50's Introduction to Artificial Intelligence with Python. It contains my solutions for the 12 projects from the course, along with the lecture source codes. The course covers various AI concepts with great lectures and engaging projects.",
    "description": [
      "Implemented AI algorithms across 12 projects, covering search, knowledge representation, machine learning, neural networks, and reinforcement learning.",
      "Projects include Tic-Tac-Toe AI (minimax), Degrees (BFS), Knights (DFS/Backtracking), Minesweeper AI (inference), PageRank (Markov Chains), Heredity (Bayesian Networks), Crossword (constraint satisfaction), Shopping (perceptron classifier), Parser (probabilistic CKY parsing), Traffic (neural networks for traffic signs categorization), and Nim (Q-learning).",
      "All projects were officially graded by CS50 Harvard team and passed successfully.",
      "Refer to the README.md inside the repository for detailed instructions on running each project."
    ],
    "skills": [
      ["Python", "purple"],
      ["AI Agent", "teal"],
      ["Machine Learning", "teal"]
    ],
    "image": "https://i.insider.com/5edfbda2f34d05695061b327",
    "isGroup": false,
    "stars": 2,
    "actions": [
      {
        "label": "View Demo",
        "url": "TODO",
        "isDisabled": true
      },
      {
        "label": "View Source Code",
        "url": "https://github.com/michac789/CS50-AI"
      }
    ]
  },
  {
    "title": "CS50 Web",
    "intro": "This repository is based on the course CS50's Web Programming with Python and JavaScript. It contains the solution for the 5 different projects offered from this course along with the relevant lecture notes and other codes I dumped during the course.",
    "description": [
      "Topics covered: HTML, CSS & SCSS, Bootstrap, Git, Python, Django, SQL & ORM & Migrations, JavaScript, APIs, Single Page Applications, React, Testing, CI/CD, scalability, security, and deployment.",
      "Projects include Commerce (auction website), Network (social network website), Mail (single page application email website), Finance (stock trading app), and Wiki (wikipedia mockup).",
      "All projects were officially graded by CS50 Harvard team and passed successfully.",
    ],
    "skills": [
      ["Python", "purple"],
      ["JavaScript", "purple"],
      ["Django", "green"],
      ["React", "blue"],
      ['MVC Web', 'cyan'],
      ['Single Page Application', 'cyan'],
      ['Unit Tests', 'orange'],
      ['Docker', 'red'],
      ['Git', 'orange'],
      ['GitHub', 'orange'],
      ['CI/CD', 'orange'],
    ],
    "image": "https://i.insider.com/5edfbda2f34d05695061b327",
    "isGroup": false,
    "stars": 2,
    "actions": [
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/CS50-Web',
      }
    ],
  },
  {
    "title": "Quixo CLI Game & AI",
    "intro": "A command-line implementation of the Quixo board game with an AI opponent using the Minimax algorithm with Alpha-Beta Pruning. Includes unit tests to ensure correctness and strategy optimization.",
    "description": [
      "Implemented a CLI-based Quixo board game with a fully functional AI player.",
      "Used the Minimax algorithm with Alpha-Beta Pruning to make efficient move decisions for the AI player.",
    ],
    "skills": [
      ["Python", "purple"],
      ["Game AI", "teal"],
      ["Minimax Algorithm", "teal"],
      ["Alpha-Beta Pruning", "teal"]
    ],
    "image": "/images/placeholder.png",
    "isGroup": false,
    "stars": 1,
    "actions": [
      {
        "label": "View Source Code",
        "url": "https://github.com/michac789/Quixo-Game"
      }
    ]
  },
  {
    "title": "Network",
    "intro": "Social media clone where user can login and create post, and follow other people.",
    "description": [
      "Created features such as user pagination, profile, login, logout and create account using session-based authentication.",
    ],
    "skills": [
      ["Python", "purple"],
      ["JavaScript", "purple"],
      ["Django", "green"],
      ['MVC Web', 'cyan'],
      ['API', 'cyan'],
    ],
    "image": "/images/network.jpg",
    "isGroup": false,
    "stars": 1,
    "actions": [
      {
        'label': 'View Source Code',
        'url': 'https://github.com/michac789/CS50-Web',
      }
    ],
  },
  {
    "title": "Commerce App",
    "intro": "A full-featured commerce platform with user authentication, item listings with images, buying and selling functionality, cart management, checkout, and integrated chat with sellers.",
    "description": [
      "Developed core e-commerce features using the MVC pattern, including a product catalog, shopping cart, and seller management.",
      "Implemented real-time chat using a long polling mechanism to facilitate communication between buyers and sellers."
    ],
    "skills": [
      ["Python", "purple"],
      ["JavaScript", "purple"],
      ["Django", "green"],
      ["MVC Web", "cyan"],
      ["REST API", "cyan"]
    ],
    "image": "/images/network.jpg",
    "isGroup": true,
    "stars": 1,
    "actions": [
      {
        "label": "View Source Code",
        "url": "https://github.com/michac789/CommerceApp"
      }
    ]
  },
]
