import React from "react";
import { Box, Heading, VStack } from '@chakra-ui/react';
import { CERTIFICATIONS, EDUCATIONS } from "../../constants/education";
import CertificationCard from "./CertificationCard";
import EducationCard from "./EducationCard";

const Education = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" py={{ base: 6, md: 8 }} px={4}>
      <Heading size="lg" mb={{ base: 4, md: 6 }} textAlign="center">
        Education
      </Heading>
      <VStack spacing={4} width="100%" maxW="container.lg">
        {EDUCATIONS.map((education, index) => (
          <EducationCard key={index} education={education} />
        ))}
        <CertificationCard certifications={CERTIFICATIONS} />
      </VStack>
    </Box>
  );
};

export default Education;
