import React from "react";
import {
  Heading,
  Flex,
  Text,
  VStack,
  HStack,
  List,
  ListItem,
  Tag,
  TagLabel,
  TagLeftIcon,
  Wrap,
  Box,
  Image,
  Button,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { FaUsers, FaUser } from 'react-icons/fa';
import { ProjectExperience } from "../../constants/projects";

const starTooltips = {
  1: "⭐ 1-star projects are small-scale and simple, usually completed in a few days.",
  2: "⭐⭐ 2-star projects are mid-level, requiring 1-2 weeks to develop with moderate complexity.",
  3: "⭐⭐⭐ 3-star projects are large-scale and advanced, typically taking over a month to complete."
};

const ProjectCard = ({ project }: { project: ProjectExperience }) => {
  return (
    <Flex
      p={{ base: 4, md: 6 }}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg="gray.50"
      flexDirection={{ base: "column", md: "row" }}
      gap={{ base: 4, md: 6 }}
      w="100%"
      position="relative"
    >
      {project.image && (
        <Image
          src={project.image}
          alt={project.title}
          borderRadius="md"
          objectFit={{ base: "contain", md: "cover" }}
          mt={{ base: 7, md: 0 }}
          boxSize={{ base: "auto", md: "150px" }}
          width={{ base: "100%", md: "150px" }}
          aspectRatio={{ base: 2, md: "auto" }}
        />
      )}

      <VStack align="start" spacing={2} flex="1">
        <HStack spacing={3} align="center" justify="space-between" w="100%">
          <Heading as="h3" size="md">
            {project.title}
          </Heading>
        </HStack>

        {project.intro && (
          <Text fontSize="sm" color="gray.700">
            {project.intro}
          </Text>
        )}

        <List spacing={1} pl={4} styleType="disc">
          {project.description.map((point, i) => (
            <ListItem key={i} fontSize="sm" color="gray.700">
              {point}
            </ListItem>
          ))}
        </List>

        <Box w="100%" mt={0.5}>
          <Wrap spacing={2}>
            {project.skills.map(([skill, color], i) => (
              <Tag key={i} colorScheme={color} size="sm">
                {skill}
              </Tag>
            ))}
          </Wrap>
        </Box>

        <HStack spacing={2} mt={3}>
          {project.actions.map((action, i) => (
            <Button
              key={i}
              as="a"
              onClick={() => {
                if (!action.isDisabled) {
                  window.open(action.url, "_blank");
                }
              }}
              isDisabled={action.isDisabled}
              colorScheme="blue"
              size="sm"
              cursor={action.isDisabled ? "not-allowed" : "pointer"}
            >
              {action.label}
            </Button>
          ))}
        </HStack>
      </VStack>

      <HStack
        position="absolute"
        top={{ base: 4, md: 6 }}
        right={{ base: 4, md: 6 }}
        alignItems="center"
        gap={4}
      >
        {project.isGroup ? (
          <Tag ml={2} colorScheme='blue' size='sm' variant='outline'>
            <TagLeftIcon boxSize='12px' as={FaUsers} />
            <TagLabel>Group Project</TagLabel>
          </Tag>
        ) : (
          <Tag ml={2} colorScheme='teal' size='sm' variant='outline'>
            <TagLeftIcon boxSize='12px' as={FaUser} />
            <TagLabel>Individual Project</TagLabel>
          </Tag>
        )}
        <Tooltip label={starTooltips[project.stars]} hasArrow>
          <HStack w="75px" justify="center">
            {[...Array(3)].map((_, i) => (
              <Icon
                as={StarIcon}
                key={i}
                color={i < project.stars ? "yellow.400" : "gray.300"}
              />
            ))}
          </HStack>
        </Tooltip>
      </HStack>

    </Flex>
  );
};

export default ProjectCard;
