import React from "react";
import {
  Card,
  Heading,
  Link,
  List,
  ListItem,
  Text,
  VStack,
  Divider,
  HStack,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Certification } from "../../constants/education";

const CertificationCard = ({ certifications }: { certifications: Certification[] }) => {
  return (
    <Card width="100%" variant="outline" p={{ base: 4, md: 6 }} boxShadow="md">
      <Heading as="h3" size="md" mb={{ base: 2, md: 4 }} textAlign="center">
        Relevant Certifications
      </Heading>
      <Divider mb={4} />
      <List spacing={4}>
        {certifications.map((cert, index) => (
          <ListItem key={index}>
            <HStack justify="space-between" width="100%">
              <VStack align="start" spacing={1}>
                <Text fontWeight="bold" fontSize="md">{cert.name}</Text>
                <Text fontSize="sm" color="gray.600">{cert.institution}</Text>
              </VStack>
              <HStack>
                <Text fontSize="sm" color="gray.500">{cert.year}</Text>
                {cert.link && (
                  <Link href={cert.link} isExternal color="blue.600" fontSize="sm">
                    View <ExternalLinkIcon mx="2px" />
                  </Link>
                )}
              </HStack>
            </HStack>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default CertificationCard;
