import React from 'react';
import { Box, Button, Flex, Heading, Text, Icon, VStack, Stack, Link, Tooltip } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaPhone, FaEnvelope, FaTelegramPlane, FaLinkedin, FaGithub, FaCopy } from 'react-icons/fa';
import { RECOMMENDATION_LETTERS } from '../../constants/letter';

const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionLink = motion(Link);

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const giggleEffect = {
  whileHover: { rotate: [0, -5, 5, 0], transition: { duration: 0.3 } },
};

const Home: React.FC = () => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    alert(`Copied: ${text}`);
  };

  return (
    <Box mt={12} width="100%" maxW="container.lg" p={6}>
      <VStack spacing={6} align="center" textAlign="center">
        <MotionHeading
          as="h1"
          size="2xl"
          fontWeight="bold"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          Hi, I'm Michael Andrew Chan
        </MotionHeading>

        <MotionText
          fontSize="lg"
          color="gray.600"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          Software Engineer · Singapore
        </MotionText>

        <MotionText
          fontSize="lg"
          maxW="2xl"
          color="gray.700"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          Welcome to my personal website, where I showcase my projects and experiences 
          in software engineering beyond what a resume can capture. 
          This site is constantly evolving with new updates, so stay tuned!
        </MotionText>

        <Stack direction={{ base: "column", md: "row" }} spacing={6} align="center">
          <Tooltip label="Click to copy" hasArrow>
            <Flex align="center" gap={2} cursor="pointer" onClick={() => copyToClipboard("+65 9428 9104")}> 
              <Icon as={FaPhone} color="teal.500" />
              <MotionText fontSize="md" fontWeight="medium" whileHover={{ scale: 1.1 }}> 
                (+65) 9428 9104
              </MotionText>
              <Icon as={FaCopy} color="gray.500" />
            </Flex>
          </Tooltip>
          
          <MotionLink href="mailto:mich0107@e.ntu.edu.sg" isExternal {...giggleEffect}>
            <Flex align="center" gap={2}>
              <Icon as={FaEnvelope} color="red.500" />
              <Text fontSize="md" fontWeight="medium">mich0107@e.ntu.edu.sg</Text>
            </Flex>
          </MotionLink>
          
          <MotionLink href="mailto:michaelac978@gmail.com" isExternal {...giggleEffect}>
            <Flex align="center" gap={2}>
              <Icon as={FaEnvelope} color="red.500" />
              <Text fontSize="md" fontWeight="medium">michaelac978@gmail.com</Text>
            </Flex>
          </MotionLink>
          
          <MotionLink href="https://t.me/michaelac9" isExternal {...giggleEffect}>
            <Flex align="center" gap={2}>
              <Icon as={FaTelegramPlane} color="blue.500" />
              <Text fontSize="md" fontWeight="medium">@michaelac9</Text>
            </Flex>
          </MotionLink>
        </Stack>
        
        <Stack direction={{ base: "column", md: "row" }} spacing={6} align="center" mt={4}>
          <MotionLink href="https://www.linkedin.com/in/michaelandrewchan/" isExternal {...giggleEffect}>
            <Flex align="center" gap={2}>
              <Icon as={FaLinkedin} color="blue.600" boxSize={6} />
              <Text fontSize="md" fontWeight="medium">LinkedIn</Text>
            </Flex>
          </MotionLink>
          
          <MotionLink href="https://github.com/michac789" isExternal {...giggleEffect}>
            <Flex align="center" gap={2}>
              <Icon as={FaGithub} color="black" boxSize={6} />
              <Text fontSize="md" fontWeight="medium">GitHub</Text>
            </Flex>
          </MotionLink>
        </Stack>
        
      </VStack>

      <Box mt={12} p={8} borderWidth={1} borderRadius="lg" boxShadow="md">
        <VStack spacing={4} align="center">
          <MotionHeading as="h3" size="lg" initial="hidden" animate="visible" variants={fadeIn}>
            Recommendation Letters
          </MotionHeading>
          <MotionText fontSize="md" color="gray.600" initial="hidden" animate="visible" variants={fadeIn}>
            See what others have to say about my work and impact!
          </MotionText>
          <Flex wrap="wrap" justify="center" gap={4}>
            {RECOMMENDATION_LETTERS.map((letter, index) => (
              <MotionButton
                key={index}
                colorScheme="teal"
                variant="solid"
                onClick={() => window.open(letter.link, '_blank')}
                whileHover={{ scale: 1.05 }}
              >
                {letter.name}
              </MotionButton>
            ))}
          </Flex>
        </VStack>
      </Box>
    </Box>
  );
};

const MotionButton = motion(Button);

export default Home;
