import React from "react";
import { Heading, Flex, Text, VStack, Stack, List, ListItem, Tag, Wrap, Box } from '@chakra-ui/react';
import { CCAExperience } from "../../constants/cca";

const CCACard = ({ cca }: { cca: CCAExperience }) => {
  return (
    <Flex
      p={{ base: 4, md: 6 }}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg="gray.50"
      flexDirection={{ base: 'column', md: 'row' }}
      gap={6}
      w="100%"
    >
      <VStack align="start" spacing={2} flex="1">
        <Stack
          direction={{ base: "column", md: "row" }} 
          spacing={1} 
          align={{ base: "flex-start", md: "center" }} 
          justify="space-between" 
          w="100%"
        >
          <Heading as="h3" size="md">
            {cca.position}
          </Heading>
          <Text fontSize="md" color="gray.700" textAlign={{ base: "left", md: "right" }}>
            {cca.duration}
          </Text>
        </Stack>

        <VStack spacing={0} align="start">
          <Text fontWeight="bold" fontSize="lg" color="gray.700">
            {cca.organization}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {cca.location}
          </Text>
        </VStack>
        
        {cca.intro && (
          <Text fontSize="sm" color="gray.700">
            {cca.intro}
          </Text>
        )}

        <List spacing={1} pl={4} styleType="disc">
          {cca.description.map((point, i) => (
            <ListItem key={i} fontSize="sm" color="gray.700">
              {point}
            </ListItem>
          ))}
        </List>

        <Box w="100%" mt={0.5}>
          <Wrap spacing={2} position="relative">
            {cca.skills.map(([skill, color], i) => (
              <Tag key={i} colorScheme={color} size="sm">
                {skill}
              </Tag>
            ))}
          </Wrap>
        </Box>
      </VStack>
    </Flex>
  );
};

export default CCACard;
