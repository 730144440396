
export interface CCAExperience {
  organization: string;
  position: string;
  location: string;
  duration: string;
  intro?: string;
  description: string[];
  skills: [string, string][];
}

export const CCA_EXPERIENCES: CCAExperience[] = [
  {
    organization: 'NTU Students\' Union Information Technology Committee',
    position: 'Chairperson (Development Lead) AY 2023/2024',
    location: 'Nanyang Technological University, Singapore',
    duration: 'Oct 2023 - Sep 2024',
    intro: 'As the Tech Lead of NTUSU ITC, I oversee the development and maintenance of ITC services, collaborate with the executive committee, and lead a team of backend developers, frontend developers, and UI/UX designers to build digital products for NTU students.',
    description: [
      'Spearheaded the development of NTUMods, a module management web application using Next.js (React) and Django, launched beta in Dec 2023 and growing to 3,000+ monthly users by Aug 2024 (data via Google Analytics).',
      'Spearheaded the development of ULocker, a digital locker booking system launched in Mar 2024, serving hundreds of students by streamlining the previously manual process and removing the need for in-person bookings.',
      'Conducted code reviews & provided technical mentorship, ensuring code quality, best practices, and long-term maintainability.',
      'Collaborated with the UI/UX designers and stakeholders, refining Figma designs, user stories, and product requirements to align with operational needs.',
    ],
    skills: [
      ['Python', 'purple'],
      ['TypeScript', 'purple'],

      ['React', 'blue'],
      ['Next.js', 'blue'],
      ['Zustand', 'blue'],

      ['Django', 'green'],
      ['MySQL', 'green'],

      ['REST API', 'cyan'],
    
      ['AWS', 'red'],
      ['Docker', 'red'],
    
      ['GitHub', 'orange'],
      ['CI/CD', 'orange'],
    
      ['Leadership', 'pink'],
      ['Project Management', 'pink'],
      ['Code Review', 'pink'],
    ],
  },
  {
    organization: 'PINTU (NTU Indonesian Students Association)',
    position: 'Technology Director AY 2023/2024',
    location: 'Nanyang Technological University, Singapore',
    duration: 'Sep 2023 - Sep 2024',
    intro: 'As the technology director of PINTU, I am responsible for leading a team of developers, with a focus on backend development and DevOps. I am also responsible for the deployment and cloud infrastructure management of various PINTU services and other sub-hoc organization projects.',
    description: [
      'Handled IaaS cloud infrastructure in Digital Ocean, managing networking and domain record from namecheap and digitalocean, deploying using docker swarm and GitLab container registry, setup gunicorn web server, reverse proxy usign Caddy / Nginx, and error monitoring using Sentry.',
      'Led the development of various projects: CI/CD for automated deployment, PINTU Form (new product), telegram chatbot service, website chatbot using langchain, and mobile app using react native.',
      'Conducted internal workshop on topic of backend development, provide guidance and mentorship.',
      'Created Telegram bot backend repository for user and perform raw sql integration, allowing user to access pintu service via the bot.',
      'Created a websocket backend for chatbot service, integrating with ai model using langchain to chat about PINTU specific services based on context.',
    ],
    skills: [
      ['Python', 'purple'],
      ['JavaScript', 'purple'],

      ['React', 'blue'],
      ['React Native', 'blue'],

      ['Django', 'green'],
      ['PostgreSQL', 'green'],

      ['REST API', 'cyan'],
      ['WebSocket', 'cyan'],

      ['Chatbot', 'teal'],
      ['LangChain', 'teal'],
    
      ['DigitalOcean', 'red'],
      ['Docker', 'red'],
      ['Cloud Infrastructure', 'red'],
    
      ['GitLab', 'orange'],
      ['CI/CD', 'orange'],
    
      ['Leadership', 'pink'],
      ['Project Management', 'pink'],
      ['Code Review', 'pink'],
    ],
  },
  {
    organization: 'NTU Students\' Union Information Technology Committee',
    position: 'Chairperson (Development Lead) AY 2022/2023',
    location: 'Nanyang Technological University, Singapore',
    duration: 'Oct 2022 - Sep 2023',
    intro: 'As the Tech Lead of NTUSU ITC, I oversee the development and maintenance of ITC services, collaborate with the executive committee, and lead a team of backend developers, frontend developers, and UI/UX designers to build digital products for NTU students.',
    description: [
      'Led a team of 11 people in full stack development of NTUSU Portal from scratch utilizing Django REST Framework & React Typescript (with Redux & Tailwind CSS).',
      'Oversaw and innovated redesign of NTUSU Portal, resulting in a modern and user-friendly interface, improved accessibility and satisfaction rates from students.',
      'Handled DevOps activities such as deployment leveraging AWS Elastic Beanstalk, EC2, S3, diagnosing issues, configuring Docker container, automating CI/CD pipelines, and performing code reviews.',
      'Conducted a Django workshop, fostering proficiency in web application development.',
    ],
    skills: [
      ['Python', 'purple'],
      ['JavaScript', 'purple'],
      ['TypeScript', 'purple'],

      ['HTML', 'blue'],
      ['CSS', 'blue'],
      ['React', 'blue'],
      ['Redux', 'blue'],
      ['TailwindCSS', 'blue'],

      ['Django', 'green'],
      ['MySQL', 'green'],

      ['REST API', 'cyan'],
    
      ['AWS', 'red'],
      ['Docker', 'red'],
    
      ['GitHub', 'orange'],
      ['CI/CD', 'orange'],
    
      ['Leadership', 'pink'],
      ['Project Management', 'pink'],
      ['Code Review', 'pink'],
    ],
  },
  {
    organization: 'PINTU (NTU Indonesian Students Association)',
    position: 'Backend Developer',
    location: 'Nanyang Technological University, Singapore',
    duration: 'Sep 2022 - Oct 2023',
    description: [
      'Orchestrated database design, created REST API endpoints for the backend of PINTU App.',
      'Involved in model design and implementation, and collaborated with frontend developers to integrate backend services.',
    ],
    skills: [
      ['Python', 'purple'],

      ['Django', 'green'],
      ['REST API', 'cyan'],
    
      ['Docker', 'red'],
    
      ['GitLab', 'orange'],
      
      ['Code Review', 'pink'],
    ],
  },
  // {
  //   organization: 'Huawei, in collaboration with Machine Learning and Data Analytics (MLDA @EEE)',
  //   position: 'Research Officer',
  //   location: 'Nanyang Technological University, Singapore',
  //   duration: 'Aug 2022 - Mar 2023',
  //   intro: 'Conducted research from Huawei regarding Conditional Image Retrieval.',
  //   description: [
  //     // 'Conducted research from Huawei regarding Conditional Image Retrieval.',
  //   ],
  //   skills: [
  //     ['Research', 'blue'],
  //     ['Machine Learning', 'red'],
  //   ],
  // },
  {
    organization: 'Machine Learning and Data Analytics (MLDA @EEE)',
    position: 'Research Officer',
    location: 'Nanyang Technological University, Singapore',
    duration: 'Aug 2022 - Mar 2023',
    // intro: 'Conducted research from Huawei regarding Conditional Image Retrieval.',
    description: [
      'Conducted research from Huawei regarding Conditional Image Retrieval.',
    ],
    skills: [
      ['Research', 'blue'],
      ['Machine Learning', 'red'],
    ],
  },
  {
    organization: 'NTU Students\' Union Information Technology Committee',
    position: 'Full-Stack Developer',
    location: 'Nanyang Technological University, Singapore',
    duration: 'Oct 2021 - Sep 2022',
    // intro: 'As the Tech Lead of NTUSU ITC, I oversee the development and maintenance of ITC services, collaborate with the executive committee, and lead a team of backend developers, frontend developers, and UI/UX designers to build digital products for NTU students.',
    description: [
      'Maintained full-stack Django web applications; implemented features, performed bug fixes, and created unit tests.',
      'Utilized Strapi to handle data management and API creation for locker booking platform, and frontend solutions leveraging React Typescript with Ant Design.',
    ],
    skills: [
      ['Python', 'purple'],
      ['JavaScript', 'purple'],

      ['HTML', 'blue'],
      ['CSS', 'blue'],
      ['React', 'blue'],

      ['Django', 'green'],

      ['GitHub', 'orange'],
    ],
  },
  {
    organization: 'SPMS Community Involvement Programme (CIP)',
    position: 'Head Programmer',
    location: 'Nanyang Technological University, Singapore',
    duration: 'Dec 2021 - Jul 2022',
    intro: 'Main committee member of SPMS CIP, educating student refugees in Malaysia through online interactive lessons and games.',
    description: [
      // 'Conducted research from Huawei regarding Conditional Image Retrieval.',
    ],
    skills: [
      ['Leadership', 'pink'],
    ],
  },
]
