
export interface Education {
  institution: string;
  location: string;
  duration: string;
  program: string;
  description: string[];
  image?: string;
}

export const EDUCATIONS: Education[] = [
  {
    institution: 'Nanyang Technological University',
    program: 'Bachelor\'s Degree (First Class Honours)',
    duration: '2021 - 2025',
    location: 'Singapore',
    description: [
      'Major: Applied Mathematics, Computing & Data Analysis',
      'ASEAN Undergraduate Scholarship recipient, full coverage of tuition fees and living allowance',
      'Tuition-Grant Holder with 3-year working bond in Singapore',
      'Confirmed First Class Honours (Final GPA: 4.54/5.00)',
    ],
    image: 'https://images.squarespace-cdn.com/content/v1/58ac54992e69cfa96ffb2fee/1572428895518-9G3QQ02FOTM654QH3KD2/NTU+Learning+Hub+Hive+33%2C+image+by+Andrew+Campbell+Nelson.jpg',
  },
  {
    institution: 'SMAK 1 Penabur Jakarta',
    program: 'High School Diploma, Natural Sciences Stream',
    duration: '2018 - 2021',
    location: 'Jakarta, Indonesia',
    description: [
      'CCA: Web Design, Animation, Multimedia',
      'Society: Physics Club',
      'Volunteering: mathematics tutor at Rumah Cerdas Penjaringan, teaching underprivileged children',
      '2nd Place IPB National Physics Competition 2019',
      '1st Place APCOM Regional Physics Competition 2020',
      '1st Highest Group FERMAT Mathematics Canadian Contest 2020 from Waterloo University',
      '1st Place Yamaha PMC Piano Pop / Keyboard Competition 2019',
    ],
    image: 'https://asset-2.tstatic.net/wartakota/foto/bank/images/bpk-1.jpg',
  },
]

export interface Certification {
  name: string;
  institution: string;
  year: string;
  link?: string;
}

export const CERTIFICATIONS: Certification[] = [
  {
    'name': 'CS50 Introduction to Computer Science',
    'institution': 'Harvard University',
    'year': '2020',
    'link': 'https://certificates.cs50.io/66fb77a5-2d15-4b98-89e0-71d7969ea9c5.pdf',
  },
  {
    'name': 'CS50 Artifical Intelligence with Python',
    'institution': 'Harvard University',
    'year': '2022',
    'link': 'https://certificates.cs50.io/541d8231-75d9-4f86-9a2d-5901f47839cf.pdf',
  },
  {
    'name': 'CS50 Web Programming with Python and JavaScript',
    'institution': 'Harvard University',
    'year': '2022',
    'link': 'https://certificates.cs50.io/5e3b495b-8963-4925-88ad-27374b8859dd.pdf',
  },
  {
    'name': 'The Web Developer Bootcamp 2022 (JavaScript, Node.js, Express, MongoDB)',
    'institution': 'Udemy - Colt Steele',
    'year': '2022',
    'link': 'https://www.udemy.com/certificate/UC-83b1fcaa-9a40-4e60-af3d-40ed513a56db',
  },
  {
    'name': 'Ultimate AWS Certified Solutions Architect Associate',
    'institution': 'Udemy - Stephane Maarek',
    'year': '2023',
    'link': 'https://www.udemy.com/certificate/UC-33fa6ae5-78be-49a7-8295-eb8749dc335a',
  },
  {
    'name': 'URECA Undergraduate Research Programme - Computer Chess AI',
    'institution': 'Nanyang Technological University',
    'year': '2023',
    'link': 'https://drive.google.com/file/d/1s5_0AV81ENV4TVX8jPxhBrP3I_1L6eBt',
  },
]
