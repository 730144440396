import React from "react";
import { 
  Card, Flex, Heading, HStack, Image, List, Link, 
  ListItem, Text, VStack 
} from "@chakra-ui/react";
import { Education } from "../../constants/education";

const EducationCard = ({ education }: { education: Education }) => {
  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    document.querySelector('#cca')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Card 
      direction={{ base: "column", md: "row" }} 
      overflow="hidden" 
      width="100%" 
      variant="outline"
      boxShadow="md"
    >
      {education.image && (
        <Image
          src={education.image}
          alt={education.institution}
          display={{ base: "block", md: "block" }}
          width={{ base: "100%", md: "30%" }}
          maxHeight={{ base: "200px", md: "100%" }}
          objectFit="cover"
        />
      )}

      <Flex
        p={{ base: 4, md: 6 }}
        width="100%"
        flexDirection="column"
      >
        <VStack align="start" spacing={2} flex="1" width="100%">

          <VStack
            align="start" 
            spacing={0} 
            w="100%" 
            display={{ base: "flex", md: "none" }}
          >
            <Heading as="h3" size="md">{education.institution}</Heading>
            <Text fontSize="md" color="gray.700">{education.duration}</Text>
          </VStack>

          <HStack 
            spacing={3} 
            align="center" 
            justify="space-between" 
            w="100%" 
            display={{ base: "none", md: "flex" }}
          >
            <Heading as="h3" size="md">{education.institution}</Heading>
            <Text fontSize="md" color="gray.700" textAlign="right" flex="1">
              {education.duration}
            </Text>
          </HStack>

          {education.program && (
            <VStack spacing={0} align="start">
              <Text fontWeight="bold" fontSize="lg" color="gray.700">
                {education.program}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {education.location}
              </Text>
            </VStack>
          )}

          <List spacing={1} pl={4} styleType="disc">
            {education.description.map((point, i) => (
              <ListItem key={i} fontSize="sm" color="gray.700">
                {point}
              </ListItem>
            ))}
            {education.location === 'Singapore' && (
              <ListItem fontSize="sm">
                <Link href="#cca" color="blue.600" onClick={handleScroll}>
                  View Co-Curricular Activities below
                </Link>
              </ListItem>
            )}
          </List>
        </VStack>
      </Flex>
    </Card>
  );
};

export default EducationCard;
