import React from "react";
import { Box, Heading, VStack } from "@chakra-ui/react";
import { PROJECTS } from "../../constants/projects";
import ProjectCard from "./ProjectCard";

const Projects: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" py={{ base: 6, md: 8 }} px={4}>
      <Heading size="lg" mb={{ base: 4, md: 6 }} textAlign="center">
        Projects
      </Heading>
      <VStack spacing={4} width="100%" maxW="container.lg">
        {PROJECTS.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </VStack>
    </Box>
  );
};
export default Projects;
