import React from "react";
import { Heading, Flex, Text, VStack, Image, List, ListItem, Tag, Wrap, Box, Stack } from '@chakra-ui/react';
import { WorkExperience } from "../../constants/work";

const WorkCard = ({ work }: { work: WorkExperience }) => {
  return (
    <Flex
      p={{ base: 4, md: 6 }}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg="gray.50"
      flexDirection={{ base: 'column', md: 'row' }}
      gap={{ base: 4, md: 6 }}
      w="100%"
    >
      <Image
        src={work.image}
        alt={work.company}
        boxSize="150px"
        borderRadius="md"
        objectFit="cover"
        flexShrink={0}
      />

      <VStack align="start" spacing={2} flex="1">
        <Stack 
          direction={{ base: "column", md: "row" }} 
          spacing={1} 
          align={{ base: "flex-start", md: "center" }} 
          justify="space-between" 
          w="100%"
        >
          <Heading as="h3" size="md">
            {work.position}
          </Heading>
          <Text fontSize="md" color="gray.700" textAlign={{ base: "left", md: "right" }}>
            {work.duration}
          </Text>
        </Stack>

        <VStack spacing={0} align="start">
          <Text fontWeight="bold" fontSize="lg" color="gray.700">
            {work.company}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {work.location}
          </Text>
        </VStack>

        <List spacing={1} pl={4} styleType="disc">
          {work.description.map((point, i) => (
            <ListItem key={i} fontSize="sm" color="gray.700">
              {point}
            </ListItem>
          ))}
        </List>

        <Box w="100%" mt={0.5}>
          <Wrap spacing={2} position="relative">
            {work.skills.map(([skill, color], i) => (
              <Tag key={i} colorScheme={color} size="sm">
                {skill}
              </Tag>
            ))}
          </Wrap>
        </Box>
      </VStack>
    </Flex>
  );
};

export default WorkCard;
