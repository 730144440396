import { useState, useEffect } from "react";
import { Box, Flex, Button, Drawer, DrawerOverlay, DrawerBody, DrawerContent, IconButton, VStack, useDisclosure } from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import CCA from "./CCA/CCA";
import Education from "./Education/Education";
import Home from "./Home/Home";
import Projects from "./Projects/Projects";
import Work from "./Work/Work";
import Footer from "../components/PageLayout/Footer";

const sections = [
  "intro",
  "projects",
  "education",
  "work",
  "cca",
] as const;
type Section = (typeof sections)[number];

const sectionComponents: Record<Section, React.FC> = {
  intro: Home,
  projects: Projects,
  education: Education,
  work: Work,
  cca: CCA,
};

const PersonalWebsite: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Section>("intro");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleScroll = () => {
      let currentSection: Section = "intro";

      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2
          ) {
            currentSection = section;
          }
        }
      });

      setActiveTab(currentSection);
    };

    const contentContainer = document.getElementById("content-container");
    contentContainer?.addEventListener("scroll", handleScroll);
    return () => contentContainer?.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <Flex
        as="nav"
        position="fixed"
        top="0"
        width="100%"
        bg="white"
        boxShadow="lg"
        p="4"
        justifyContent={{ base: "space-between", md: "center" }}
        alignItems="center"
        zIndex="1000"
      >
        <IconButton
          aria-label="Open Menu"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          display={{ base: "block", md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />

        <Box display={{ base: "block", md: "none" }} paddingRight={4} fontWeight="bold" fontSize="lg" color="gray.700">
          Personal Website
        </Box>

        <Flex as="nav" width="100%" gap="4" justifyContent="center" display={{ base: "none", md: "flex" }}>
          {sections.map((section) => (
            <Button
              key={section}
              onClick={() => {
                document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
                onClose();
              }}
              colorScheme={activeTab === section ? "teal" : "gray"}
              variant={activeTab === section ? "solid" : "outline"}
              borderWidth="2px"
              borderColor={activeTab === section ? "teal.500" : "gray.500"}
              flex="1"
              maxW="150px"
            >
              {section.toUpperCase()}
            </Button>
          ))}
        </Flex>
      </Flex>

      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <Flex justifyContent="space-between" alignItems="center" mt="4" mb="6">
              <Box textAlign="center" fontSize="lg" fontWeight="bold" color="gray.600" flex="1">
                Personal Website
              </Box>
              <IconButton
                aria-label="Close Drawer"
                icon={<CloseIcon />}
                onClick={onClose}
                size="sm"
                variant="ghost"
              />
            </Flex>

            <VStack spacing="4">
              {sections.map((section) => (
                <Button
                  key={section}
                  onClick={() => {
                    document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
                    onClose();
                  }}
                  width="100%"
                  colorScheme={activeTab === section ? "teal" : "gray"}
                  variant={activeTab === section ? "solid" : "outline"}
                >
                  {section.toUpperCase()}
                </Button>
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box id="content-container" flex="1" overflowY="auto" mt="72px">
        {sections.map((section) => {
          const SectionComponent = sectionComponents[section];
          return (
            <Box
              id={section}
              key={section}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderBottom="1px solid"
              borderColor="gray.300"
            >
              <SectionComponent />
            </Box>
          );
        })}
        <Footer />
      </Box>
    </Box>
  );
};

export default PersonalWebsite;
