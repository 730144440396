import React from "react";
import { Box, Flex, Text, IconButton, Link, Stack } from "@chakra-ui/react";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <Box
      as="footer"
      bottom="0"
      width="100%"
      bg="gray.700"
      color="gray.300"
      boxShadow="lg"
      p="3"
      zIndex="1000"
    >
      <Flex 
        direction={{ base: "column", md: "column", lg: "row" }} 
        align="center" 
        textAlign="center" 
        gap={{ base: 2, lg: 4 }}
        justify="space-between"
        wrap="wrap"
        maxW="800px"
        mx="auto"
      >
        <Text fontSize="sm">
          © {new Date().getFullYear()} Michael Andrew Chan. All rights reserved.
        </Text>

        <Stack 
          direction={{ base: "column", sm: "row" }} 
          spacing={{ base: 1, sm: 4 }}
          align="center"
        >
          <Text fontSize="sm">📞 +65 9428 9104</Text>
          <Text fontSize="sm">✉️ mich0107@e.ntu.edu.sg</Text>
        </Stack>

        <Flex gap="4">
          <Link href="https://github.com/michac789" isExternal>
            <IconButton
              aria-label="GitHub"
              icon={<FaGithub />}
              variant="ghost"
              color="gray.300"
              fontSize="20px"
              _hover={{ color: "white" }}
              size="sm"
            />
          </Link>

          <Link href="https://linkedin.com/in/michaelandrewchan" isExternal>
            <IconButton
              aria-label="LinkedIn"
              icon={<FaLinkedin />}
              variant="ghost"
              color="gray.300"
              fontSize="20px"
              _hover={{ color: "white" }}
              size="sm"
            />
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
