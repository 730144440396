import React from "react";
import { Box, Heading, VStack } from '@chakra-ui/react';
import { CCA_EXPERIENCES } from "../../constants/cca";
import CCACard from "./CCACard";

const CCA = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" py={8} px={4}>
      <Heading size="lg" mb={{ base: 4, md: 6 }} textAlign="center">
        CCA Experiences
      </Heading>
      <VStack spacing={4} width="100%" maxW="container.lg">
        {CCA_EXPERIENCES.map((cca, index) => (
          <CCACard key={index} cca={cca} />
        ))}
      </VStack>
    </Box>
  );
};

export default CCA;
