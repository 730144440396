
export interface WorkExperience {
  company: string;
  position: string;
  location: string;
  duration: string;
  description: string[];
  image?: string;
  skills: [string, string][];
}

export const WORK_EXPERIENCES: WorkExperience[] = [
  {
    company: 'Continental',
    position: 'R&D Software Engineer Intern',
    location: 'Boon Keng, Singapore (Hybrid)',
    duration: 'Jan 2025 - May 2025',
    description: [
      'Credit-bearing internship at Continental - Software and Central Technologies (SCT) Department',
      'Supported the R&D team by developing and maintaining software solutions, including data collection, rapid application prototyping, user interfaces creation, model/algorithm integration, and utilizing cloud-based technologies.',
      'Developed an EV charging station simulation app using React (Next.js, TypeScript, Google Maps API) and Python Flask, collaborating with UI/UX designers, algorithm engineers, and product managers to deliver a functional prototype to Volkswagen client within 2 weeks.',
      'Utilized C and C++ for embedded systems and IoT, collecting GPS and LIDAR logs from autonomous vehicles, and processing them for model training and testing.',
      'Prototyped and tested a C# WebGL Unity application for vehicle crash simulation, integrated with a REST API backend, and deployed using AWS Cloudfront.',
    ],
    image: 'https://michaelac-portfolio-public.s3.ap-southeast-1.amazonaws.com/images-experience/company-continental.jpg',
    skills: [
      ['C', 'purple'],
      ['C++', 'purple'],
      ['C#', 'purple'],
      ['Python', 'purple'],
      ['TypeScript', 'purple'],

      ['Unity', 'yellow'],

      ['React', 'blue'],
      ['Next.js', 'blue'],

      ['Flask', 'green'],
      ['SQL', 'green'],

      ['REST API', 'cyan'],

      ['Embedded Systems', 'teal'],

      ['Linux', 'red'],
      ['AWS', 'red'],
      ['Docker', 'red'],

      ['GitHub', 'orange'],
    ],
  },
  {
    company: 'SLB (Schlumberger)',
    position: 'Software (Data) Engineer Intern',
    location: 'Benoi Sector, Singapore (Hybrid)',
    duration: 'May 2024 - Aug 2024',
    description: [
      'Q&T Department - Data Engineering Team',
      'Built an executable Python Flask & JavaScript desktop app for engineers to visualize, analyze, and export automated vibration reports in PDF and Excel formats, improving data accessibility and workflow efficiency.',
      'Optimized FFT and time-series analysis algorithms using NumPy, Pandas, and Matplotlib for anomaly detection, allowing predictive maintenance capabilities.',
      'Engineered an ETL pipeline using MQTT, Kafka, and Apache Spark for real-time vibration data processing, integrating with database on Azure, automating data processing and visualizations.',
      'Utilized Power BI and JavaScript to create customized visualizations, presented key insights to stakeholders.',
    ],
    image: 'https://michaelac-portfolio-public.s3.ap-southeast-1.amazonaws.com/images-experience/company-slb.jpg',
    skills: [
      ['Python', 'purple'],
      ['JavaScript', 'purple'],

      ['HTML', 'blue'],
      ['CSS', 'blue'],
      ['Bootstrap', 'blue'],
    
      ['Flask', 'green'],
      ['Pandas', 'green'],
      ['PostgreSQL', 'green'],

      ['MQTT', 'cyan'],

      ['Data Engineering', 'teal'],
      ['Algorithm Development', 'teal'],

      ['Azure', 'red'],
      ['Docker', 'red'],

      ['GitHub', 'orange'],

      ['Power BI', 'pink'],
    ],
  },
  {
    company: 'Syngenta',
    position: 'Full-Stack Engineer (Intern, Contract)',
    location: ' Science Park, Singapore (Hybrid)',
    duration: 'Nov 2023 - May 2024',
    description: [
      'APAC Operational Technology Team',
      'Full-Time Intern (Nov 2023 - Jan 2024):',
      'Enhanced a Mixed Reality (MR) application on HoloLens 2 using C#, Unity, and MRTK, adding new features and sections while integrating REST API and OPC UA, creating an immersive smart lab experience.',
      'Fixed bugs and optimized query of warehouse management system codebase utilizing C# and .NET, reducing some query execution time by around 40%.',
      'Assisted in MLOps for a machine learning project on capping quality control using Python and Scikit-Learn Random Forest Classifier, with input data streamed from OPC UA and deployed on Litmus Edge for real-time inference.',
      'Part-Time Contract (Jan 2024 - May 2024):',
      'Developed a Manufacturing Execution System (MES) web app using React (TypeScript), Django (Python), and PostgreSQL, boosting operational efficiency across production processes.',
      'Integrated SAP and OPC UA server for real-time manufacturing data, leveraging WebSockets for real-time communication, RabbitMQ for work order task queues, and cron jobs via crontab for scheduled reporting.',
      'Utilized Docker and Docker Swarm for containerization and orchestration, ensuring scalability, consistency and availability.',
      'Liaised with key stakeholders to translate functional requirements into technical solutions and intuitive UI designs.',
    ],
    image: 'https://michaelac-portfolio-public.s3.ap-southeast-1.amazonaws.com/images-experience/company-syngenta.jpg',
    skills: [
      ['C#', 'purple'],
      ['Python', 'purple'],
      ['TypeScript', 'purple'],

      ['Unity', 'yellow'],
      ['Mixed Reality', 'yellow'],

      ['HTML', 'blue'],
      ['React', 'blue'],
      ['Jest', 'blue'],
    
      ['.NET', 'green'],
      ['Django', 'green'],
      ['PostgreSQL', 'green'],

      ['REST API', 'cyan'],
      ['WebSocket', 'cyan'],
      ['OPC UA', 'cyan'],

      ['MLOps', 'teal'],
      ['RabbitMQ', 'teal'],

      ['Docker', 'red'],

      ['GitLab', 'orange'],
    ],
  },
  {
    company: 'Glints',
    position: 'Software Engineer Intern',
    location: 'One North, Singapore (Hybrid)',
    duration: 'May 2023 - Nov 2023',
    description: [
      'Full-Time (May 2023 - Aug 2023), Part-Time (Aug 2023 - Nov 2023); worked in Shared Services team, then transitioned to Managed Talent team.',
      'Collaborated with a cross-functional, multinational team in 2-week Scrum sprints using Jira, consistently delivering 100% of sprint targets on time.',
      'Developed the Managed Talent web application frontend in React and TypeScript, implementing responsive UI components from Figma designs and features such as form validation, Magic Link authentication, and GraphQL API integration.',
      'Developed the Managed Talent backend in Rust, leveraging Diesel ORM to design and optimize GraphQL APIs, and implemented a templated email-sending service.',
      'Automated application deployments utilizing Kubernetes Helm Charts, AWS, and GitLab CI/CD.',
      'Crafted and extended customized React components in Glints Aries, Glints\' in-house UI component library; implemented Playwright E2E tests and integrated Storybook, improving development efficiency and UI consistency across all Glints products.',
      'Developed a resume PDF generator microservice (Node.js, Express.js, AWS S3), integrated in Glints Candidates Site (Next.js), serving millions of candidates across South-East Asia.',
      'Maintained and fixed bugs in monolithic Node.js API backend, ensuring system stability and improving developer experience.',
    ],
    image: 'https://michaelac-portfolio-public.s3.ap-southeast-1.amazonaws.com/images-experience/company-glints.jpg',
    skills: [
      ['JavaScript', 'purple'],
      ['TypeScript', 'purple'],
      ['Rust', 'purple'],

      ['CSS', 'blue'], 
      ['React', 'blue'],
      ['Redux', 'blue'],
      ['Next.js', 'blue'],
      ['Playwright', 'blue'],
    
      ['Node.js', 'green'],
      ['SQL', 'green'],

      ['GraphQL', 'cyan'],
    
      ['AWS', 'red'],
      ['Linux', 'red'],
      ['Docker', 'red'],
      ['Kubernetes', 'red'],
    
      ['GitLab', 'orange'],
      ['CI/CD', 'orange'],
    
      ['Scrum', 'pink'],
    ]
  },
  {
    company: 'Wardaya College',
    position: 'H2 Mathematics & Physics Teacher',
    location: 'West Jakarta, Indonesia (Remote)',
    duration: 'Dec 2021 - May 2022',
    description: [
      'Created and discussed challenging H2 A-Level equivalent Mathematics & Physics questions in an interactive online class of over 100 high school students, in preparation for NTU University Entrance Examination.',
      'Provided personalized university consultation and mock interviews, resulting in 10 students securing ASEAN Undergraduate Scholarship in NTU.',
    ],
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQo32OqBrDfhaUb7dupX024bYwGnfL2dssSMA&s',
    skills: [
      ['Mathematics', 'pink'],
      ['Physics', 'pink'],
      ['Teaching', 'pink'],
      ['Interview Coaching', 'pink'],
    ]
  },
]
